import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import Sidebar from '../components/Sidebar'

export default function About() {
  return (
    <Layout>
      <section>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className="col-sm-12 col-lg-6">
              <p>
                I became knowledgeable in the glass field during my early years… Being connected to my uncle who started and maintained a successful stained glass company here in Austin in 1972… and I as a 14 year old teenager who visited frequently from New Orleans during the summer breaks from school is where I first found interest in leaded glass. 
              </p>
              <p>
                My mother as a consequence developed an interest in the craft and began taking this on as a hobby at home. Between these two influences I found myself drawn to it naturally. 
              </p>
              <p>
                I subsequently then found myself moving to Austin to work under the tutelage of my uncle who taught me more and all I know about the craft and have since developed my own valuable knowledge about this continuing decreasingly lost form of art.
              </p>
              <p>
                It provides a personal sense of satisfaction that I'm able to provide a service to those who think that there are no options available to remedy… and returning a product that will be as close to its original condition as is possible.
              </p>
            </div>
            <div className="col-sm-12 col-lg-3 d-flex justify-content-center mb-5">
              <div>
                <Sidebar />
                <StaticImage 
                  src='../images/IMG6111-225x300.jpg'
                  width = { 225 }
                  alt='logo'
                  placeholder='tracedSVG'
                  quality='40'
                  />
              </div>
            </div>
          </div>
        </div>
      </section> 

    </Layout>

  )
}
